import {
    AsyncPipe,
    DOCUMENT
} from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Inject,
    OnInit
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import {
    Event,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    Router,
    RouterOutlet,
} from '@angular/router';
import {
    TranslocoEvents,
    TranslocoService,
} from '@jsverse/transloco';
import {
    UntilDestroy,
    untilDestroyed,
} from '@ngneat/until-destroy';
import {
    Actions,
    ofActionSuccessful,
} from '@ngxs/store';
import * as Sentry from '@sentry/angular';
import {
    distinctUntilChanged,
    distinctUntilKeyChanged,
    filter,
    first,
    map,
    merge,
    skip,
    switchMap,
} from 'rxjs';
import {
    take,
    tap,
} from 'rxjs/operators';

import {
    BROADCAST_CHANNEL_AUTH_CHANGE_TOKEN,
    BROADCAST_CHANNEL_CONSENT_CHANGE_TOKEN,
    BROADCAST_REFRESH_SEARCH_UNIVERSES
} from '~/app/shared/constants/broadcast-channels.constants';
import { ENVESTBOARD_UNIVERSE_ID } from '~/app/domains/shares/constants/shares.constants';
import { ConsentProviderFacade } from '~/app/domains/consent/services/common/consent-provider.facade';
import { BroadcastChannelService } from '~/app/shared/services/broadcast-channel/broadcast-channel.service';
import {
    UpdateApplicationConfigurationAction,
} from '~/app/core/state/configuration/configuration.action';
import { ConfigurationFacade } from '~/app/core/state/configuration/configuration.facade';
import { LocaleService } from '~/app/shared/services/locale/locale.service';
import { BaseTrackingService } from '~/app/shared/services/tracking/base-tracking.service';
import { AuthenticationTokens } from '~/app/domains/authentication/types/authentication-tokens.type';
import { BroadcastSearchUniverse } from '~/app/shared/types/broadcast/delete-search-universe.type';
import { ConsentState } from '~/app/domains/consent/types/consent-state.type';
import { User } from '~/app/shared/types/user/user.type';
import { environment } from '~/environments/environment';

import { ApplicationFacade } from './core/state/application/application-facade/application.facade';
import { AuthenticationFacade } from './core/state/authentication/authentication.facade';
import { PortfoliosListFacade } from './core/state/portfolios-list/portfolios-list.facade';
import { SharesListFacade } from './core/state/shares-list/shares-list.facade';
import { TurnstileComponent } from '~/app/domains/authentication/components/turnstile/turnstile.component';
import packageInfo from '../../package.json';
import { TranslocoHttpLoader } from '~/app/shared/services/transloco/transloco-loader';

@UntilDestroy()
@Component({
    selector: 'eb-root',
    templateUrl: './app.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        TurnstileComponent,
        RouterOutlet,
        AsyncPipe,
    ]
})
export class AppComponent implements OnInit {
    public isLoading = true;

    public isChallengeRequired = this.configurationFacade.getChallengeRequiredSnapshot();

    public consentProviderFacade = inject(ConsentProviderFacade);
    protected readonly environment = environment;

    constructor(
        private localeService: LocaleService,
        private dateAdapter: DateAdapter<any>,
        private router: Router,
        private titleService: Title,
        public authenticationFacade: AuthenticationFacade,
        private applicationFacade: ApplicationFacade,
        private sharesListFacade: SharesListFacade,
        private portfolioListFacade: PortfoliosListFacade,
        @Inject(DOCUMENT) private document: Document,
        private translocoHttpLoader: TranslocoHttpLoader,
        private translocoService: TranslocoService,
        private broadcastChannelService: BroadcastChannelService,
        private actions$: Actions,
        private trackingService: BaseTrackingService,
        // private oauthProvider: OAuthProvider,
        private configurationFacade: ConfigurationFacade,
    ) {
        if (this.configurationFacade.getIsMatomoTrackingEnabledSnapshot()) {
            this.trackingService.insertScript(this.configurationFacade.getTrackinMatomoSnapshot());
            this.updateMatomoUserId();
        }

        this.translocoService.events$
            .pipe(
                filter((e: TranslocoEvents) => e.type === 'translationLoadSuccess' || e.type === 'langChanged'),
                map((e) => e?.payload),
                distinctUntilChanged(((prev, curr) => prev.langName === curr.langName)),
                switchMap(({ langName }) => this.translocoHttpLoader.getTranslationFromAPI(langName)),
                map((translation) => this.translocoService.setTranslation(translation)),
                untilDestroyed(this),
            ).subscribe();

        this.authenticationFacade.userLanguage$
            .pipe(
                take(1),
                tap((language) => void this.localeService.initLocale(language)),
                untilDestroyed(this),
            ).subscribe();

        this.localeService.currentDateFnsAdapterLocale$
            .pipe(untilDestroyed(this))
            .subscribe((lang) => {
                this.dateAdapter.setLocale(lang);
            });

        if (this.configurationFacade.getIsMatomoTrackingEnabledSnapshot()) {
            this.router.events
                .pipe(
                    filter((event: Event) => event instanceof NavigationEnd),
                    tap(() => this.trackingService.pageView()),
                    untilDestroyed(this),
                )
                .subscribe();
        }

        this.router.events
            .pipe(
                filter((event: Event) => event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError),
                first(),
                map(() => false),
                untilDestroyed(this),
            )
            .subscribe((isLoading: boolean) => {
                this.isLoading = isLoading;
            });

        this.titleService.setTitle(this.configurationFacade.getPlatformNameSnapshot());
    }


    public ngOnInit(): void {

        console.info(`🚀 ${this.configurationFacade?.getPlatformNameSnapshot()} version: ${packageInfo?.version}`);

        if (!environment.production) {

            console.info(`Matomo status: ${this.configurationFacade.getIsMatomoTrackingEnabledSnapshot() ? 'enabled' : 'disabled'}`);
        }
        if (environment.production) {
            this.actions$
                .pipe(
                    ofActionSuccessful(UpdateApplicationConfigurationAction),
                    map(() => {
                        Sentry.init({
                            dsn: atob(this.configurationFacade?.getTrackingSentrySnapshot()?.dsn || ''),
                            environment: this.configurationFacade?.getTrackingSentrySnapshot()?.extras?.env ?? undefined,
                        });
                    }),
                    untilDestroyed(this),
                ).subscribe();
        }

        // Reset configuration after signin and signout
        merge(
            this.authenticationFacade.onLogin(),
            this.authenticationFacade.onLogout(),
        ).pipe(
            tap(() => {
                this.updateMatomoUserId();
            }),
            switchMap(() => this.configurationFacade.getConfiguration()),
            untilDestroyed(this),
        ).subscribe();

        this.broadcastChannelService.messagesOfType<AuthenticationTokens>(BROADCAST_CHANNEL_AUTH_CHANGE_TOKEN)
            .pipe(
                tap((message) => this.authenticationFacade.setAuthenticationTokens(message.payload)),
                untilDestroyed(this),
            )
            .subscribe();

        this.broadcastChannelService.messagesOfType<ConsentState>(BROADCAST_CHANNEL_CONSENT_CHANGE_TOKEN)
            .pipe(
                tap((message) => this.consentProviderFacade.updateIsConsentAccepted(message.payload.state)),
                untilDestroyed(this),
            )
            .subscribe();

        this.broadcastChannelService
            .messagesOfType<BroadcastSearchUniverse>(BROADCAST_REFRESH_SEARCH_UNIVERSES).pipe(
                tap(() => {
                    this.sharesListFacade.changeSearchUniverse(ENVESTBOARD_UNIVERSE_ID);
                    this.sharesListFacade.getSearchUniverses();
                }),
                untilDestroyed(this),
            )
            .subscribe();

        this.configurationFacade?.colorPalette$?.pipe(
            tap((colorPalette) => {
                if (colorPalette) {
                    Object.keys(colorPalette).forEach((key) => {
                        this.document.documentElement.style.setProperty(`--${key}`, colorPalette[key]);
                    });
                }
            }),
            untilDestroyed(this),
        ).subscribe();

        this.authenticationFacade.userLanguage$
            .pipe(
                skip(1),
                tap((language) => void this.localeService.setLocale(language)),
                distinctUntilChanged(),
                switchMap(() => this.authenticationFacade.user$.pipe(
                    filter((user): user is User => user !== null && user !== undefined && user.id !== undefined),
                    distinctUntilKeyChanged('id'),
                    tap((user) => {
                        if (user?.id) {
                            this.applicationFacade.initApplication();
                            this.sharesListFacade.emptyShares();
                            this.sharesListFacade.search(true);
                            this.portfolioListFacade.getPortfolioMetaData(true);
                            this.applicationFacade.getMenus();
                        }
                    }),
                )),
                untilDestroyed(this),
            ).subscribe();
    }

    public refreshChallengeToken(challenge: string) {
        this.authenticationFacade.refreshChallengeToken(challenge)
            .pipe(untilDestroyed(this))
            .subscribe();
    }
    private updateMatomoUserId() {
        const user = this.authenticationFacade.getUserSnapshot();
        const userId = user?.email || 'anonymous';
        this.trackingService.updateUserId(userId);
    }

}
